.paginationWrapper {
  padding: 2rem 0;
  display: flex;
  justify-content: center;
}
.separator {
  width: 16px;
  margin: 0 0.25rem;
}
.pageItem {
  background: transparent;
  border: none;
  height: 35px;
  width: 35px;
  margin: 0 0.25rem;
  border-radius: 50%;
  font-weight: 600;
  color: #000;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: 0;
  }
}
.active {
  background-color: #000;
  color: #fff;
}
.sides {
  box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
    rgba(0, 0, 0, 0.18) 0px 2px 4px;
  &:hover {
    text-decoration: none;
    box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
      rgba(0, 0, 0, 0.12) 0px 6px 16px;
  }
}
