.layout{
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  background: #f5f8ff;
}
.container{
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 20px;
  text-align: center;
  p {
    margin-top: -10px;
    color: #777;
  }
}
input[type="file"] {
  display: none;
}

.boxUpload{
  cursor: pointer;
  display: grid;
  margin: 20px;
  place-items: center;
  border: 1px dashed #799cd9;
  position: relative;
  height: 120px;
  width: 120px;
  background: #fbfbff;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
}

.imageUpload {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  cursor: pointer;

  label {
    :hover {
      opacity: 0.8;
    }
  }
  > input {
    display: none;
  }
}
.uploadedImage {
  height: 20%;
  width: 20%;
}

.closeIcon {
  background: #000;
  border-radius: 5px;
  opacity: 0.8;
  position: absolute;
  z-index: 10;
  right: 15px;
  top: 20px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }

}


