.formImageWrap {
  border: 3px solid #fff;
  box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.075);
  width: 125px;
  height: 125px;
  position: relative;
}

.fromUploadFile {
  background-color: red;
}

.penUploadImg {
  top: -15px;
  right: -15px;
  box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.075);
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.btnRemovePreview {
  position: absolute;
  bottom: -15px;
  right: -15px;
  z-index: 10;
  box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.075);
  background-color: #fff;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.objectFitCover {
  object-fit: cover;
}

.overlayAvatar {
  background-color: rgba(0, 0, 0, 0.075);
}